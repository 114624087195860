import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import Sparessheader from "../include/sparess-header";
import SparessSidemenu from "../include/sparess-sidemanu";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";
import { Modal } from "react-bootstrap";
import { apiRequest } from "../../config/apiRequest";
import { API_END_URLS } from "../../config/endUrls";
import { showMessage } from "../../helpers/toast";
import { Link, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { S3Bucket } from "../../config/constants";
import moment from "moment/moment";
import PostingManagerSideMenu from "../include/postingManagerSideMenu";
import { Pagination } from "@mui/material";
// import Tab from "react-bootstrap/Tab";

function Posting() {
  const navigate = useNavigate();
  const [productsData, setProductsData] = useState([]);
  const [serviceProducts, setServiceProducts] = useState([]);

  const [noOfProductPages, setNoOfProductPages] = useState(0);
  const [noOfPageService, setNoOfPageService] = useState(0);

  const [query, setQuery] = useState({
    page: 1,
    limit: 5,
  });

  /* Handle Modal */
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);
  const [currentItem, setCurrentItem] = useState(null);
  const [productCount, setProductCount] = useState({});
  const [statistics, setStatistics] = useState("product");

  const getProductListing = async () => {
    setIsOpen(false);
    const { data, success, message } = await apiRequest({
      endUrl: API_END_URLS.getAllProduct,
      method: "get",
      token: true,
      query: query,
    });

    if (success) {
      setProductsData(data?.product);
      setNoOfProductPages(
        Math.ceil(data?.product_pagination?.total / query.limit)
      );
      setNoOfPageService(
        Math.ceil(data?.service_product_pagination?.total / query.limit)
      );
      setServiceProducts(data?.service_product);
    } else {
      showMessage(message);
    }
  };

  const getProductCount = async () => {
    const { data, success, message } = await apiRequest({
      endUrl: API_END_URLS.getProductCount,
      method: "get",
      token: true,
    });

    if (success) {
      setProductCount(data);
    } else {
      showMessage(message);
    }
  };

  const handleProductPageChange = (page) => {
    setQuery({ ...query, page: page });
  };

  const handleServicePageChange = (page) => {
    setQuery({ ...query, page: page });
  };

  useEffect(() => {
    getProductListing();
    getProductCount();
  }, [query]);

  useEffect(() => {}, [statistics]);
  return (
    <React.Fragment>
      <DeleteModal
        isOpen={isOpen}
        onClose={toggleModal}
        currentItem={currentItem}
        onDelete={getProductListing}
      />
      <div class="container-fluid" id="seller-panel">
        <div class="row">
          <div class="col-md-3 pr-0 d-none d-sm-block">
            <SparessSidemenu></SparessSidemenu>
          </div>
          <div class="col-md-9 p-0 col-12">
            <div class="main-box">
              <div class="page-headpaddingbox">
                <Sparessheader></Sparessheader>
              </div>
              <div class="pageinnner-padding padding-rightnone">
                <div className="row">
                  <PostingManagerSideMenu />

                  <div className="col-md-9 p-sm-0">
                    <div className="right-contentbox">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="posting-box">
                            {statistics === "product" ? (
                              <div className="row">
                                <div className="col-md-12 static-area">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div className="static-head">
                                      <h1>Statistics</h1>
                                    </div>

                                    <div className="static-select">
                                      <input
                                        type="date"
                                        className="form-control"
                                        value="2024-08-01"
                                        onChange={(e) =>
                                          console.log(e.target.value)
                                        } 
                                      />
                                    </div>
                                  </div>

                                  <div className="row mt-4">
                                    <div className="col-md-3 pr-0">
                                      <div className="d-flex align-items-center">
                                        <div className="postig-icon">
                                          <img
                                            src="/images/postig-icon-1.png"
                                            alt="/"
                                          />
                                        </div>
                                        <div className="posting-text">
                                          <h1>
                                            {productCount?.postingProduct}{" "}
                                          </h1>
                                          <p>Postings Uploaded</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3 p-0">
                                      <div className="d-flex align-items-center">
                                        <div className="postig-icon">
                                          <img
                                            src="/images/postig-icon-2.png"
                                            alt="/"
                                          />
                                        </div>
                                        <div className="posting-text">
                                          <h1>{productCount?.productDraft} </h1>
                                          <p>Pending Uploads (Draft)</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3 p-0">
                                      <div className="d-flex align-items-center">
                                        <div className="postig-icon">
                                          <img
                                            src="/images/postig-icon-3.png"
                                            alt="/"
                                          />
                                        </div>
                                        <div className="posting-text">
                                          <h1>
                                            {productCount?.productExpired}{" "}
                                          </h1>
                                          <p>Postings Expired</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3 pl-0">
                                      <div className="d-flex align-items-center">
                                        <div className="postig-icon">
                                          <img
                                            src="/images/postig-icon-4.png"
                                            alt="/"
                                          />
                                        </div>
                                        <div className="posting-text">
                                          <h1>
                                            {productCount?.remainingPostinPlan}{" "}
                                          </h1>
                                          <p>Remaining Post in Plan</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="row">
                                <div className="col-md-12 static-area">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div className="static-head">
                                      <h1>Statistics</h1>
                                    </div>

                                    <div className="static-select">
                                      <select className="form-control">
                                        <option>08/01/2024 </option>
                                      </select>
                                    </div>
                                  </div>

                                  <div className="row mt-4">
                                    <div className="col-md-3 pr-0">
                                      <div className="d-flex align-items-center">
                                        <div className="postig-icon">
                                          <img
                                            src="/images/postig-icon-1.png"
                                            alt="/"
                                          />
                                        </div>
                                        <div className="posting-text">
                                          <h1>
                                            {
                                              productCount?.servicePostingProduct
                                            }{" "}
                                          </h1>
                                          <p>Postings Uploaded</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3 p-0">
                                      <div className="d-flex align-items-center">
                                        <div className="postig-icon">
                                          <img
                                            src="/images/postig-icon-2.png"
                                            alt="/"
                                          />
                                        </div>
                                        <div className="posting-text">
                                          <h1>
                                            {productCount?.serviceProductDraft}{" "}
                                          </h1>
                                          <p>Pending Uploads (Draft)</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3 p-0">
                                      <div className="d-flex align-items-center">
                                        <div className="postig-icon">
                                          <img
                                            src="/images/postig-icon-3.png"
                                            alt="/"
                                          />
                                        </div>
                                        <div className="posting-text">
                                          <h1>
                                            {
                                              productCount?.servicePproductExpired
                                            }{" "}
                                          </h1>
                                          <p>Postings Expired</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3 pl-0">
                                      <div className="d-flex align-items-center">
                                        <div className="postig-icon">
                                          <img
                                            src="/images/postig-icon-4.png"
                                            alt="/"
                                          />
                                        </div>
                                        <div className="posting-text">
                                          <h1>
                                            {productCount?.remainingPostinPlan}{" "}
                                          </h1>
                                          <p>Remaining Post in Plan</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="row mt-4">
                              <div className="col-md-12 p-sm-0">
                                <Tab.Container
                                  id="left-tabs-example"
                                  defaultActiveKey="product"
                                >
                                  <div className="row justify-content-end">
                                    <div className="col-md-4 col-9">
                                      <Nav className="user-tabs cost-tab justify-content-end pb-0">
                                        <Nav.Item>
                                          <Nav.Link
                                            eventKey="product"
                                            onClick={() =>
                                              setStatistics("product")
                                            }
                                          >
                                            Product
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link
                                            eventKey="service"
                                            onClick={() =>
                                              setStatistics("service")
                                            }
                                          >
                                            Service
                                          </Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </div>
                                    <div className="col-md-1 pl-sm-0 col-8 mt-sm-0 mt-3">
                                      <Link to="##">
                                        <button
                                          type="button"
                                          class="btn btn-filter"
                                          to="##"
                                        >
                                          <img
                                            src="/images/filter-icon.png"
                                            alt="/"
                                          />
                                        </button>
                                      </Link>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                      <Tab.Content>
                                        <Tab.Pane eventKey="product">
                                          <div className="row mt-3">
                                            <div className="col-md-12">
                                              <div class="table-responsive">
                                                <Table className="posting-table">
                                                  <thead>
                                                    <tr>
                                                      <th>Product</th>
                                                      <th>Posting Date</th>
                                                      <th>Exp. Date</th>
                                                      <th>Price </th>
                                                      <th>Catagory</th>
                                                      <th>Sub Catagory</th>
                                                      <th>Action</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {productsData?.map(
                                                      (item) => {
                                                        return (
                                                          <tr>
                                                            <td>
                                                              <div class="table-imgbox">
                                                                <div class="table-firstimg">
                                                                  <img
                                                                    src={
                                                                      item
                                                                        ?.ProductImages[0]
                                                                        ?.image_url
                                                                    }
                                                                    alt="/"
                                                                  />
                                                                </div>
                                                                <div>
                                                                  <p>
                                                                    {
                                                                      item
                                                                        ?.CategoryProduct
                                                                        ?.product_name
                                                                    }{" "}
                                                                    <br></br>{" "}
                                                                    {
                                                                      item?.brand
                                                                    }
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              {moment(
                                                                item?.createdAt
                                                              ).format(
                                                                "DD-MM-YYYY"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {item?.expiry_date
                                                                ? moment(
                                                                    item?.expiry_date
                                                                  ).format(
                                                                    "DD-MM-YYYY"
                                                                  )
                                                                : "No Expiry Date Available"}
                                                            </td>
                                                            <td>
                                                              {item?.amount}
                                                            </td>
                                                            <td>
                                                              {
                                                                item?.Category
                                                                  ?.category_name
                                                              }
                                                            </td>
                                                            <td>
                                                              {
                                                                item?.sub_category
                                                              }
                                                            </td>
                                                            <td>
                                                              <div class="row">
                                                                <div className="col-md-6">
                                                                  {(item?.status ===
                                                                    "draft" && (
                                                                    <button
                                                                      type="button"
                                                                      class="btn btn-edit mr-5"
                                                                      onClick={() =>
                                                                        navigate(
                                                                          `/seller/posting/add-product/${item.id}/draft`
                                                                        )
                                                                      }
                                                                    >
                                                                      Draft{" "}
                                                                    </button>
                                                                  )) ||
                                                                    (item?.status ===
                                                                      "active" && (
                                                                      <button
                                                                        type="button"
                                                                        class="btn btn-edit mr-5"
                                                                        onClick={() =>
                                                                          navigate(
                                                                            `/seller/posting/add-product/${item.id}/draft`
                                                                          )
                                                                        }
                                                                      >
                                                                        Update{" "}
                                                                      </button>
                                                                    )) ||
                                                                    (item?.status ===
                                                                      "expired" && (
                                                                      <button
                                                                        type="button"
                                                                        class="btn btn-edit mr-5"
                                                                      >
                                                                        Expired{" "}
                                                                      </button>
                                                                    ))}
                                                                </div>
                                                                <div className="col-md-6">
                                                                  <button
                                                                    type="button"
                                                                    class="btn btn-edit"
                                                                    onClick={() => {
                                                                      toggleModal();
                                                                      setCurrentItem(
                                                                        item
                                                                      );
                                                                    }}
                                                                  >
                                                                    <img
                                                                      src="/images/delete.svg"
                                                                      alt="/"
                                                                    />
                                                                  </button>
                                                                </div>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                  </tbody>
                                                </Table>
                                              </div>

                                              <div class="row justify-content-end">
                                                <div className="col-md-3">
                                                  <div class="text-right">
                                                    {noOfProductPages ? (
                                                      <Pagination
                                                        sx={{
                                                          justifyContent: "end",
                                                        }}
                                                        count={noOfProductPages}
                                                        defaultPage={1}
                                                        siblingCount={2}
                                                        boundaryCount={2}
                                                        variant="outlined"
                                                        shape="rounded"
                                                        color="primary"
                                                        onChange={(e, page) => {
                                                          handleProductPageChange(
                                                            page
                                                          );
                                                        }}
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="service">
                                          <div className="row mt-3">
                                            <div className="col-md-12">
                                              <div class="table-responsive">
                                                <Table className="posting-table">
                                                  <thead>
                                                    <tr>
                                                      <th>Product</th>
                                                      <th>Posting Date</th>
                                                      <th>Exp. Date</th>
                                                      <th>Price </th>
                                                      <th>Catagory</th>
                                                      <th>Sub Catagory</th>
                                                      <th>Action</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {serviceProducts?.map(
                                                      (item) => {
                                                        return (
                                                          <tr>
                                                            <td>
                                                              <div class="table-imgbox">
                                                                <div class="table-firstimg">
                                                                  <img
                                                                    src={
                                                                      item
                                                                        ?.ProductImages[0]
                                                                        ?.image_url
                                                                    }
                                                                    alt="/"
                                                                  />
                                                                </div>
                                                                <div>
                                                                  <p>
                                                                    {
                                                                      item
                                                                        ?.CategoryProduct
                                                                        ?.product_name
                                                                    }{" "}
                                                                    <br></br>{" "}
                                                                    {
                                                                      item?.brand
                                                                    }
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              {moment(
                                                                item?.from_date
                                                              ).format(
                                                                "DD-MM-YYYY"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {item?.expiry_date
                                                                ? moment(
                                                                    item?.expiry_date
                                                                  ).format(
                                                                    "DD-MM-YYYY"
                                                                  )
                                                                : "No Expiry Date Available"}
                                                            </td>
                                                            <td>
                                                              {item?.amount}
                                                            </td>
                                                            <td>
                                                              {
                                                                item?.Category
                                                                  ?.category_name
                                                              }
                                                            </td>
                                                            <td>
                                                              {
                                                                item?.sub_category
                                                              }
                                                            </td>
                                                            <td>
                                                              <div class="row">
                                                                <div className="col-md-6">
                                                                  {(item?.status ===
                                                                    "draft" && (
                                                                    <button
                                                                      type="button"
                                                                      class="btn btn-edit mr-5"
                                                                      onClick={() =>
                                                                        navigate(
                                                                          `/seller/addservice/${item.id}/draft`
                                                                        )
                                                                      }
                                                                    >
                                                                      Draft{" "}
                                                                    </button>
                                                                  )) ||
                                                                    (item?.status ===
                                                                      "active" && (
                                                                      <button
                                                                        type="button"
                                                                        class="btn btn-edit mr-5"
                                                                        onClick={() =>
                                                                          navigate(
                                                                            `/seller/addservice/${item.id}/draft`
                                                                          )
                                                                        }
                                                                      >
                                                                        Update{" "}
                                                                      </button>
                                                                    )) ||
                                                                    (item?.status ===
                                                                      "expired" && (
                                                                      <button
                                                                        type="button"
                                                                        class="btn btn-edit mr-5"
                                                                      >
                                                                        Expired{" "}
                                                                      </button>
                                                                    ))}
                                                                </div>
                                                                <div className="col-md-6">
                                                                  <button
                                                                    type="button"
                                                                    class="btn btn-edit"
                                                                    onClick={() => {
                                                                      toggleModal();
                                                                      setCurrentItem(
                                                                        item
                                                                      );
                                                                    }}
                                                                  >
                                                                    <img
                                                                      src="/images/delete.svg"
                                                                      alt="/"
                                                                    />
                                                                  </button>
                                                                </div>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                  </tbody>
                                                </Table>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="row justify-content-end">
                                            <div className="col-md-5">
                                              <div class="text-right mr-3">
                                                {noOfPageService ? (
                                                  <Pagination
                                                    count={noOfProductPages}
                                                    defaultPage={1}
                                                    siblingCount={2}
                                                    boundaryCount={2}
                                                    variant="outlined"
                                                    shape="rounded"
                                                    color="primary"
                                                    onChange={(e, page) => {
                                                      handleServicePageChange(
                                                        page
                                                      );
                                                    }}
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </Tab.Pane>
                                      </Tab.Content>
                                    </div>
                                  </div>
                                </Tab.Container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Posting;

/* Delete Method and Delete Component */
const DeleteModal = ({ isOpen, onClose, currentItem, onDelete }) => {
  const deleteProduct = async () => {
    const { data, success, message } = await apiRequest({
      endUrl: "v1/seller/service/" + currentItem?.id,
      method: "delete",
    });
    if (success) {
      onDelete();
      showMessage(message);
    } else {
      showMessage(message);
    }
  };

  return (
    <Modal className="new-model dlt-model" show={isOpen} onHide={onClose}>
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <div className="row justify-content-center">
          <div class="col-md-11 pb-5">
            <div class=" dlt-sec text-center">
              <div className="delete-boxgif">
                <img src="/images/dlt-gif.gif" alt="/" />
              </div>
              <h1 className="mt-3">
                {" "}
                Are you sure!! <br></br>
                you want to delete
              </h1>
              <div class="dlt-sec-btn mt-5">
                <button
                  type="button"
                  class="btn sparessborder-btn mr-2 mr-sm-0 mr-lg-2 mb-0 mb-sm-2 mb-lg-0"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn sparessfill-btn"
                  onClick={deleteProduct}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
