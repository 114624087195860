export const API_END_URLS = {
    /* Authentication Panel  Start*/
    login: "v1/login",
    register: "v1/register",
    postVerifyOtp: "v1/verify-otp",
    securityQuestion: "v1/update-security-question",
    forgetPassword: "v1/forget-password",
    forgetPasswordVerifyOtp: "v1/forget-password/verify-otp",
    restPassword: "v1/reset-password",
    /* Authentication Panel End */

    /* Get Country Data */
    getCountries: "v1/seller/countries",

    // user panel
    getUserProducts: "v1/user/products",

    getUserProduct: "v1/user/product",
    getUserProductCategories: "v1/user/product-categories/categories",
    getUserProductSubCategories: "v1/user/product-categories/sub-categories",


    getUserServices: "v1/user/services",

    //contact-us
    postContactUs: "v1/contact-us-query",

    // seller panel

    //helpdesk
    postHelpDesk: "v1/seller/helpdesk-query",

    /* Product URL */
    getProducts: "v1/seller/products",
    getProductById: 'v1/seller/product',
    postProduct: "v1/seller/product",
    putProduct: "v1/seller/product",
    getAllProduct : "v1/seller/get-all-product-user",
    getProductDraftUpdate : "v1/seller/product-draft",
    getProductCount:"v1/seller/product-posting-count",

    
    /* Write Code By Ekta Gupta Start */

    allCategories: "v1/seller/all-categories",
    getCategoryByProduct : "v1/seller/category-product",
 
    /* Write Code By Ekta Gupta End */
    productSubCategories: "v1/seller/product-categories/sub-category",

    /* Service URL */
    getServices: "v1/seller/services",
    getServiceById: 'v1/seller/service',
    postService: "v1/seller/service",
    putService: "v1/seller/service",
    serviceParentCategories: "v1/seller/service-categories/parent",
    serviceSubCategories: "v1/seller/service-categories/sub-category",


    /* Profile URLs */
    getUserProfile: 'v1/user/profile',
    updateUserProfile: 'v1/user/profile',


    // getCMSWhoWeAre: "v1/admin/get-cms-whoWeAre",

    /* Subscription */
    getChosePlan: 'v1/admin/get-cms-subscription',
    getChooseSubscriptionPlan: 'v1/seller/subscription-plans-standard',
    // getSubscriptionAcceptance: 'v1/seller/subscription-acceptance',
    getSubscriptionAcceptance :'v1/admin/subscription-acceptance',
    subscriptionPaymentUpdated : "v1/seller/subscription-payment-redirect-url",
    subscriptionPlanSubmit : "v1/seller/subscription-payment",

    /* HelpDesk URLS */
    getHelpDesk: "v1/admin/cms-helpdesk",

    /* Footer URLS */
    getFooters: "v1/admin/cms-footers",

    /* Header URLS */
    getHeaderData: "v1/seller/getCMSHome",

    /* BLOGS URLS*/
    // getBlogs: "v1/admin/get-cms-blogs",
    getOneBlog: "v1/seller/get-one-blog",
    getReadMoreBlog: "v1/seller/readmore",
    getCatBlogs: "v1/seller/get-categories-blog",

    /* Review & Ratings */
    reviewRatingSend: "v1/seller/review-submit",
    reviewsAll: "v1/seller/reviews",
    /* File Upload*/
    fileUpload: "v1/seller/fileupload",
    reviewsGet: "v1/seller/reviews-publish",
    getAllCategories: "v1/product-categories/categories",

    /* Change Password */
    changePassword: "v1/seller/change-password",

    /* Connect API */
    connect : "v1/connect",
    connectUsers : "v1/list",
    conversationCount : "v1/conversation-count",


    /* AGORA URL  Start Code*/
    agora_url: "https://api.agora.io/v1/apps",
    getVideoToken: "v1/agora-access-token",
    getUserFatch : "v1/getUser",



    /* Advertisement Route */
    advertisementMaster : "v1/advertisement-rule",
    addAdvertisement : "v1/advertisement-create",
    advertisementUpload : "v1/advertisement-upload",
    advertisementRecord : "v1/advertisement-list",
    advertisementRecordDelete : "v1/advertisement-delete",
    advertisementStatusCount : "v1/advertisement-user-status-count",
    advertisementExpiredCount : "v1/advertisement-expiry-count",
    advertisementPayment : "v1/advertisement-create-payment",
    advertisementUpdateStatus : "v1/advertisement-payment-redirect-url",
    advertisementFilter : "v1/advertisement/filter",
    advertisemetCheck : "v1/advertisement-check-subScription",


    /* Dashboard Route Start Code*/

    /* CMS URLS  */
    getCMSRecords: 'v1/admin/getCMSHome',
    S3Bucket : "https://navigatorsrus-s3.s3.ap-south-1.amazonaws.com/navigatorsrus/",
    getDataWhatWeAre : "v1/admin/get-who-we-are",
    getRangeOfProuct: "v1/category-all-products",
    categoryCount : "v1/seller/product-count",
    serviceProductCount : "v1/seller/service-product-count",
    getBlogs : "v1/seller/get-blogs",
    getCategories : "v1/seller/get-categories",
     
    /* SeaCategory of Product list Route*/
    getSeaCategoryProductList : "v1/category-product-list",
    getDealsPromotedProduct : "v1/category-product-deals",
    /* RaneofProducts */
    getRangeOfProducts : "v1/category-products",

    /* Product Images Delete  */
    DeleteProductImage : "v1/seller/product-image/",

    /* Promotion Route */
    createPromotion : "v1/promotion-create",
    allPromotions : "v1/all-promotions",
    getCheckedPromotion: "v1/all-promotions-list",
    promotionUpdate : "v1/promotion-update",
    findOnePromotion: "v1/promotion-get",
    deletePromotion : "v1/promotion-delete",
    promotionCount : "v1/promotion-count",

    /* SEO Route */
    getSeoPath : "v1/seo-path",

    /* Wish List */
    addWishList : "v1/wishlist-create",
    wishList : "v1/wishlist",
    wishListDelete : "v1/wishlist-delete/",

    /* Push Notification */
    getDeviceToken : "v1/register-device",
    sendNotification : "v1/push-notification",
    fetchNotification : "v1/push-notification-get-data",
    notificationUpdateStatus : "v1/push-notification-update-data"
    

}